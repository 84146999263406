<template>
  <div class='payment-registration-form' ref="paymentRegForm">
    <error-toast :text="errorToast.text" :isVisible="errorToast.isVisible" />
    <section class="form-section">
      <h1 class="title">{{ $t(personalSection.sectionTitle) }}</h1>
      <div class="inputs-wrapper">
        <base-input
          v-for="(input, i) in personalSection.inputs" :key="input.key"
          :labelText="$t(input.label)"
          :hasError="input.hasError"
          :type="input.type ? input.type : 'text'"
          @input="handleInputs('personal', i, ...arguments)"
        />
      </div>
    </section>
    <section class="form-section">
      <h1 class="title">{{ $t(passwordSection.sectionTitle) }}</h1>
      <div class="inputs-wrapper">
        <base-input
          v-for="(input, i) in passwordSection.inputs" :key="input.key"
          :labelText="$t(input.label)"
          :hasError="input.hasError"
          :type="input.type ? input.type : 'text'"
          @input="handleInputs('password', i, ...arguments)"
        />
      </div>
    </section>
    <section class="checkbox-section">
      <base-checkbox
        :label="$t(policiesSection.label)"
        :isChecked="policiesSection.isChecked"
        :hasError="policiesSection.hasError"
        @changed="handleCheckboxInput"
      />
    </section>
    <base-button
      :isLoading="isBtnLoading"
      :text="$t('pages.registration.buttons.registration')"
      @clicked="handleRegistrationBtnClick"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import scroll from '@/components/shared/mixins/scroll';
import validations from '@/components/shared/mixins/validations';

import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import BaseCheckbox from '@/components/shared/elements/inputs/BaseCheckbox';
import BaseInput from '@/components/shared/elements/inputs/BaseInput';

export default {
  name: 'PaymentRegistrationForm',
  mixins: [scroll, validations],
  props: {},
  components: {
    BaseInput,
    BaseCheckbox,
    BaseButton,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    personalSection: {
      sectionTitle: 'pages.registration.form.sections.personal.title',
      inputs: [
        {
          key: 'firstName',
          label: 'pages.registration.form.sections.personal.labels.firstName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'lastName',
          label: 'pages.registration.form.sections.personal.labels.lastName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'email',
          type: 'email',
          label: 'pages.registration.form.sections.personal.labels.email',
          value: undefined,
          hasError: false,
        },
        {
          key: 'phoneNumber',
          label: 'pages.registration.form.sections.personal.labels.phoneNumber',
          value: '',
          hasError: false,
        },
      ],
    },
    passwordSection: {
      sectionTitle: 'pages.registration.form.sections.passwords.title',
      inputs: [
        {
          key: 'password',
          type: 'password',
          label: 'pages.registration.form.sections.passwords.labels.password',
          value: undefined,
          hasError: false,
        },
        {
          key: 'passwordAgain',
          type: 'password',
          label: 'pages.registration.form.sections.passwords.labels.passwordAgain',
          value: undefined,
          hasError: false,
        },
      ],
    },
    policiesSection: {
      label: 'pages.registration.form.policies',
      isChecked: false,
      hasError: false,
    },
    isBtnLoading: false,
    errorToast: {
      text: undefined,
      isVisible: false,
    },
    hasIncorrectValue: false,
  }),
  created() {
    document.addEventListener('keyup', this.handleKeyPress);
  },
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
  },
  methods: {
    ...mapActions({
      registerUser: 'users/register',
    }),
    handleInputs(sectionKey, index, value) {
      switch (sectionKey) {
        case 'personal':
          this.personalSection.inputs[index].value = value;
          break;
        case 'password':
          this.passwordSection.inputs[index].value = value;
          break;
        default:
          break;
      }
    },
    handleCheckboxInput() {
      this.policiesSection.isChecked = !this.policiesSection.isChecked;
    },
    validateForm() {
      this.hasIncorrectValue = false;

      this.personalSection.inputs.forEach((input, i) => {
        if (input.key !== 'phoneNumber' && (input.value === undefined || input.value.length === 0)) {
          this.personalSection.inputs[i].hasError = true;
        } else if (input.key === 'phoneNumber' && input.value !== '') {
          this.personalSection.inputs[i].hasError = !this.validatePhoneNumber(input.value);
          this.hasIncorrectValue = this.hasIncorrectValue ? true : this.personalSection.inputs[i].hasError;
        } else if (input.key === 'firstName' || input.key === 'lastName') {
          this.personalSection.inputs[i].hasError = !this.validateName(input.value);
          this.hasIncorrectValue = this.hasIncorrectValue ? true : this.personalSection.inputs[i].hasError;
        } else {
          this.personalSection.inputs[i].hasError = false;
        }
      });

      this.passwordSection.inputs.forEach((input, i) => {
        if (input.value === undefined || input.value.length === 0) {
          this.passwordSection.inputs[i].hasError = true;
        } else {
          this.passwordSection.inputs[i].hasError = false;
        }
      });

      if (!this.policiesSection.isChecked) {
        this.policiesSection.hasError = true;
      } else {
        this.policiesSection.hasError = false;
      }

      const personalSectionHasError = this.personalSection.inputs.filter((input) => input.hasError).length > 0;
      const passwordSectionHasError = this.passwordSection.inputs.filter((input) => input.hasError).length > 0;

      if (personalSectionHasError || passwordSectionHasError || !this.policiesSection.isChecked) {
        this.errorToast.isVisible = true;
        this.errorToast.text = this.hasIncorrectValue
          ? this.$t('general.errors.incorrectValues') : this.$t('general.errors.missingFields');
      } else {
        this.errorToast.isVisible = false;
        this.errorToast.text = undefined;
      }

      return personalSectionHasError || passwordSectionHasError || !this.policiesSection.isChecked;
    },
    validatePassword() {
      const password = this.passwordSection.inputs[0];
      const passwordAgain = this.passwordSection.inputs[1];

      if (password.value !== passwordAgain.value) {
        this.passwordSection.inputs.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.hasError = true;
        });
        this.errorToast.isVisible = true;
        this.errorToast.text = this.$t('general.errors.password');

        this.scrollToToast('paymentRegForm');

        return false;
      }

      this.passwordSection.inputs.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.hasError = false;
      });
      this.errorToast.isVisible = false;
      this.errorToast.text = undefined;

      return true;
    },
    parseRequestObject() {
      return {
        firstName: this.personalSection.inputs.filter((item) => item.key === 'firstName')[0].value,
        lastName: this.personalSection.inputs.filter((item) => item.key === 'lastName')[0].value,
        email: this.personalSection.inputs.filter((item) => item.key === 'email')[0].value,
        phoneNumber: this.personalSection.inputs.filter((item) => item.key === 'phoneNumber')[0].value,
        password: this.passwordSection.inputs.filter((item) => item.key === 'password')[0].value,
      };
    },
    handleRegistrationBtnClick() {
      const formHasError = this.validateForm();

      if (!formHasError) {
        const passwordsAreMatched = this.validatePassword();

        if (passwordsAreMatched) {
          this.isBtnLoading = true;

          const requestObj = this.parseRequestObject();

          if (this.getCurrentCart !== undefined) {
            requestObj.cart = this.getCurrentCart._id;
          }

          this.registerUser(requestObj).then(() => {
            this.isBtnLoading = false;
            this.$emit('registrationSuccessfull');
          }).catch((error) => {
            this.isBtnLoading = false;
            this.errorToast.isVisible = true;
            this.errorToast.text = error.data.message;

            this.scrollToToast('paymentRegForm');
          });
        }
      } else {
        this.scrollToToast('paymentRegForm');
      }
    },
    handleKeyPress(e) {
      if (e.key === 'Enter') {
        this.handleBtnClick();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyPress);
  },
};
</script>

<style lang='scss' scoped>
.error-toast {
  margin: 0 0 20px;
}

.form-section {
  margin: 0 0 20px;

  .title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $globalFontColor;
    margin: 0 0 8px;
  }
}

.checkbox-section {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;

  &::v-deep {
    .base-checkbox {
      .input-wrapper {
        @media screen and (max-width: 477.98px) {
          flex: 1 0 auto;
        }
      }
    }
  }
}

.inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;

  @media screen and (max-width: 477.98px) {
    grid-template-columns: 1fr;
  }
}
</style>
